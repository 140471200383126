<template>
  <v-snackbar :value="display" @input="setDisplay" :color="level">
    <v-icon>{{ symbol }}</v-icon>
    <span class="px-2">{{ text }}</span>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Alert",
  data: () => ({
    icons: {
      info: "mdi-information",
      success: "mdi-check-circle",
      error: "mdi-alert-octagon",
      warn: "mdi-alert",
    },
  }),
  computed: {
    ...mapGetters({
      display: "alert/display",
      level: "alert/level",
      text: "alert/text",
    }),
    symbol() {
      return this.icons[this.level];
    },
  },
  methods: {
    setDisplay(value) {
      this.$store.dispatch("alert/setDisplay", value);
    },
  },
};
</script>

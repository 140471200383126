<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        data-account="user"
        ref="accountButton"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar>
          <img :src="user.photoURL" v-if="user.photoURL" />
          <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <v-card ref="accountMenu">
      <v-list data-account="info">
        <v-list-item>
          <v-list-item-avatar>
            <img :src="user.photoURL" v-if="user.photoURL" />
            <v-icon v-else>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content id="name">
            <v-list-item-title>{{
              user.displayName || user.email
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <slot></slot>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "AccountMenu",
  props: {
    user: { type: Object, required: true },
  },
};
</script>

<template>
  <v-app-bar app color="white" height="64">
    <v-toolbar-title>
      <router-link
        :to="{ name: 'Home' }"
        class="logo grey--text text--darken-4"
      >
        <Logo :image="logo" :company="company" />
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>
      <Account v-if="isLoggedIn" :user="user" @signout="signout" />
      <template v-else>
        <v-btn
          data-account="signIn"
          ref="signin"
          class="text-none"
          large
          text
          :to="{ name: 'SignIn' }"
        >
          {{ $t("sign in") }}
        </v-btn>
        <v-btn
          data-account="signUp"
          ref="signup"
          class="text-none"
          large
          depressed
          color="accent"
          :to="{ name: 'SignUp' }"
        >
          {{ $t("sign up") }}
        </v-btn>
      </template>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import Account from "./Account";
export default {
  name: "Toolbar",
  components: {
    Logo,
    Account,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      user: "user/data",
      company: "config/company",
      logo: "config/logo",
    }),
  },
  methods: {
    signout() {
      this.$store.dispatch("draft/setData");
      this.$firebase.signOut();
    },
  },
};
</script>

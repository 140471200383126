<template>
  <div class="d-flex flex-row justify-space-between">
    <v-avatar tile size="32">
      <img ref="logo" id="logo" :src="image" />
    </v-avatar>
    <div ref="company" id="company" class="d-none d-sm-flex">{{ company }}</div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    image: { type: String, default: "" },
    company: { type: String, default: "" },
  },
};
</script>

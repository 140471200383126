<template>
  <v-app>
    <Toolbar />
    <Alert />
    <router-view />
  </v-app>
</template>

<style>
html {
  overflow: auto;
}
</style>

<script>
import Toolbar from "@/components/home/Toolbar";
import Alert from "@/components/home/Alert";
export default {
  name: "Home",
  components: {
    Toolbar,
    Alert,
  },
  beforeRouteEnter(to, from, next) {
    if (to.path.endsWith("/")) return next(to.path.replace(/\/$/, ""));
    next();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path.endsWith("/")) return next(to.path.replace(/\/$/, ""));
    next();
  },
};
</script>

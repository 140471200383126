<template>
  <AccountMenu :user="user">
    <v-list-item :to="{ name: 'user/drafts/new' }" data-account="newDraft">
      <v-list-item-content>
        <v-list-item-subtitle class="text-body-2 font-weight-regular">
          {{ $t("new schedule") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{ name: 'user/drafts/list' }" data-account="draftList">
      <v-list-item-content>
        <v-list-item-subtitle class="text-body-2 font-weight-regular">
          {{ $t("schedules") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{ name: 'user/settings' }" data-account="settings">
      <v-list-item-content>
        <v-list-item-subtitle class="text-body-2 font-weight-regular">
          {{ $t("settings.label") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item @click="$emit('signout')" data-account="signOut">
      <v-list-item-content>
        <v-list-item-subtitle class="text-body-2 font-weight-regular">
          {{ $t("sign out") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </AccountMenu>
</template>

<script>
import AccountMenu from "./AccountMenu";
export default {
  name: "Account",
  components: {
    AccountMenu,
  },
  props: {
    user: { type: Object, required: true },
  },
};
</script>
